<template>
    <div>{{inputData}}</div>
</template>

<script>
export default {
    name: 'PaymentLinkDetails',
    props: ['inputData'],
    created() {
        window.console.log("innput data:", this.inputData);
    }
}
</script>